/* MakeContents.css */
/* Base Styles */
:root {
  --primary-color: #2196F3;
  --primary-dark: #1976D2;
  --success-color: #4CAF50;
  --success-dark: #43A047;
  --danger-color: #f44336;
  --danger-dark: #d32f2f;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --shadow-sm: 0 1px 2px rgba(0,0,0,0.05);
  --shadow-md: 0 4px 6px -1px rgba(0,0,0,0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0,0,0,0.1);
}

/* Container */
.make-contents-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif;
  color: var(--gray-800);
}

/* Header */
.make-contents-header {
  margin-bottom: 40px;
  text-align: center;
}

.make-contents-header h1 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  color: var(--gray-900);
}

/* Server Status */
.server-status {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background: var(--gray-100);
  font-size: 14px;
  font-weight: 500;
}

.server-status::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.server-status.connected::before { 
  background: var(--success-color);
}

.server-status.disconnected::before { 
  background: var(--danger-color);
}

.server-status.connecting::before { 
  background: #ff9800;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}

/* Text Entries Section */
.text-entries-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.text-entries-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--gray-900);
}

.manage-voices-button {
  padding: 8px 16px;
  background: var(--gray-100);
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  color: var(--gray-700);
  font-weight: 500;
  transition: all 0.2s;
}

.manage-voices-button:hover {
  background: var(--gray-200);
  color: var(--gray-900);
}

/* Bulk Text Section */
.bulk-text-section {
  background: var(--gray-100);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
}

.bulk-textarea {
  width: 100%;
  height: 120px;
  padding: 16px;
  border: 2px solid var(--gray-200);
  border-radius: 12px;
  resize: vertical;
  font-size: 16px;
  line-height: 1.5;
  transition: all 0.2s;
  background: white;
}

.bulk-textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 4px rgba(33, 150, 243, 0.1);
}

.bulk-controls {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}

.bulk-split-button {
  padding: 12px 24px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.bulk-split-button:hover {
  background: var(--primary-dark);
  transform: translateY(-1px);
}

/* Voice Select */
.voice-select {
  flex: 1;
  padding: 12px 16px;
  border: 2px solid var(--gray-200);
  border-radius: 8px;
  font-size: 15px;
  color: var(--gray-800);
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.voice-select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 4px rgba(33, 150, 243, 0.1);
}

/* Entries List */
.entries-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
}

.text-entry {
  background: white;
  border: 2px solid var(--gray-200);
  border-radius: 12px;
  padding: 24px;
  transition: all 0.2s;
}

.text-entry:hover {
  border-color: var(--primary-color);
  box-shadow: var(--shadow-md);
}

.entry-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.entry-number {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-600);
  min-width: 40px;
}

.entry-textarea {
  width: 100%;
  min-height: 120px;
  padding: 16px;
  border: 2px solid var(--gray-200);
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  transition: all 0.2s;
}

.entry-textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 4px rgba(33, 150, 243, 0.1);
}

.entry-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.character-count {
  font-size: 14px;
  color: var(--gray-600);
  font-weight: 500;
}

.remove-entry-button {
  background: none;
  border: none;
  color: var(--danger-color);
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
  line-height: 1;
  border-radius: 50%;
  transition: all 0.2s;
}

.remove-entry-button:hover {
  background: rgba(244, 67, 54, 0.1);
  color: var(--danger-dark);
}

/* Action Buttons */
.entries-actions {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.add-entry-button {
  padding: 14px 28px;
  background: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.2s;
  flex: 1;
}

.add-entry-button:hover {
  background: rgba(33, 150, 243, 0.1);
  transform: translateY(-1px);
}

.generate-button {
  padding: 14px 28px;
  background: var(--success-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.2s;
  flex: 1;
}

.generate-button:hover {
  background: var(--success-dark);
  transform: translateY(-1px);
}

.generate-button:disabled {
  background: var(--gray-400);
  transform: none;
  cursor: not-allowed;
}

/* Output Section */
.output-section {
  margin-top: 48px;
  padding-top: 32px;
  border-top: 2px solid var(--gray-200);
}

.output-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--gray-900);
  margin-bottom: 24px;
}

.audio-files-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.audio-file-item {
  background: var(--gray-100);
  border-radius: 12px;
  padding: 20px;
}

.audio-file-name {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-800);
  margin-bottom: 12px;
}

.audio-file-actions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.audio-player {
  flex: 1;
  height: 40px;
}

.download-button {
  padding: 10px 20px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.download-button:hover {
  background: var(--primary-dark);
  transform: translateY(-1px);
}

.download-all-button {
  width: 100%;
  padding: 14px;
  background: var(--success-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 16px;
  transition: all 0.2s;
}

.download-all-button:hover {
  background: var(--success-dark);
  transform: translateY(-1px);
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: var(--shadow-lg);
}

.modal-header {
  padding: 24px;
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  border-radius: 16px 16px 0 0;
}

.modal-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--gray-900);
  margin: 0;
}

.close-modal {
  background: none;
  border: none;
  color: var(--gray-600);
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  transition: all 0.2s;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-modal:hover {
  background: var(--gray-100);
  color: var(--gray-900);
}

.voice-list {
  padding: 24px;
}

.voice-item {
  padding: 16px;
  background: var(--gray-100);
  border-radius: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
}

.voice-item:hover {
  background: var(--gray-200);
}

.voice-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.voice-name {
  font-weight: 600;
  font-size: 16px;
  color: var(--gray-900);
}

.voice-id,
.voice-language {
  font-size: 14px;
  color: var(--gray-600);
}

.delete-voice {
  padding: 8px 16px;
  background: var(--danger-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.delete-voice:hover {
  background: var(--danger-dark);
}

.add-voice-form {
  padding: 24px;
  border-top: 1px solid var(--gray-200);
  background: var(--gray-100);
}

.add-voice-form h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-900);
  margin-bottom: 16px;
}

.add-voice-form input,
.add-voice-form select,
.add-voice-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 2px solid var(--gray-300);
  border-radius: 8px;
  background: white;
  transition: all 0.2s;
}

.add-voice-form input:focus,
.add-voice-form select:focus,
.add-voice-form textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 4px rgba(33, 150, 243, 0.1);
}

.add-voice-form textarea {
  height: 120px;
  resize: vertical;
}

.add-voice-form button {
  width: 100%;
  padding: 14px;
  background: var(--success-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.2s;
}

.add-voice-form button:hover {
  background: var(--success-dark);
  transform: translateY(-1px);
}

/* Back Button */
.back-button {
  width: 100%;
  padding: 14px;
  background: var(--gray-600);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 32px;
  transition: all 0.2s;
}

.back-button:hover {
  background: var(--gray-700);
  transform: translateY(-1px);
} 