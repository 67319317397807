@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

:root {
  --primary: #4A90E2;
  --deep-blue: #1C3D5A;
  --light-blue: #A1C4FD;
  --neutral: #F5F7FA;
  --accent: #FFD66B;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif;
  background-color: transparent;
  color: var(--deep-blue);
  padding-top: 64px; /* Height of the fixed header */
}

/* Smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}

/* 기존의 전역 선택자(모든 div에 적용하는 배경 및 테두리)는 문제를 일으킬 수 있으므로 완전히 제거합니다. */

/* 필요한 전역 스타일은 개별 요소 또는 클래스에 한정해서 작성하는 것을 권장합니다. */ 