.header-container {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  font-family: 'Pretendard', sans-serif;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.logo {
  font-size: 1.25rem;
  font-weight: 700;
  color: #1C3D5A;
  text-decoration: none;
  transition: color 0.2s ease;
  flex-shrink: 0;
}

.logo:hover {
  color: #4A90E2;
}

.search-container {
  display: none;
}

.search-icon-button {
  background: none;
  border: none;
  padding: 6px;
  cursor: pointer;
  color: #1C3D5A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.search-icon-button:hover {
  color: #4A90E2;
  background-color: rgba(74, 144, 226, 0.1);
}

/* Search Modal */
.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 2000;
  padding-top: 80px;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  animation: fadeIn 0.2s ease;
}

.search-modal-content {
  width: 90%;
  max-width: 700px;
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  position: relative;
  animation: slideDown 0.3s ease;
  max-height: calc(90vh - 80px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-modal-form {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  background: #f5f5f7;
  border-radius: 100px;
  margin-right: 40px;
}

.search-modal-form input {
  flex: 1;
  border: none;
  outline: none;
  background: none;
  font-size: 1.1rem;
  color: #1C3D5A;
  padding: 0.5rem 0;
}

.search-modal-form input::placeholder {
  color: #999;
}

.search-modal-form svg {
  color: #666;
}

.search-results {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-right: 0.5rem;
}

.search-results::-webkit-scrollbar {
  width: 6px;
}

.search-results::-webkit-scrollbar-track {
  background: transparent;
}

.search-results::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.search-result-item {
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.search-result-item:hover {
  background: #f5f5f7;
}

.result-thumbnail {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  background: #f0f0f0;
}

.result-info {
  flex: 1;
  min-width: 0;
}

.result-info h3 {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  color: #1C3D5A;
}

.result-info p {
  margin: 0 0 0.5rem 0;
  font-size: 0.9rem;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.result-tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.result-tag {
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  background: #f0f0f0;
  border-radius: 100px;
  color: #666;
}

.search-loading {
  padding: 2rem;
  text-align: center;
  color: #666;
}

.no-results {
  padding: 2rem;
  text-align: center;
  color: #666;
}

.search-modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: none;
  border: none;
  color: #666;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  font-size: 1.1rem;
  padding: 0;
}

.search-modal-close:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-shrink: 0;
}

.nav-link {
  color: #1C3D5A;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem;
  transition: all 0.2s ease;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
}

.nav-icon {
  width: 18px;
  height: 18px;
}

.nav-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4A90E2;
  transition: width 0.2s ease;
}

.nav-link:hover {
  color: #4A90E2;
}

.nav-link:hover:after {
  width: 100%;
}

.logout-button {
  background: none;
  border: none;
  color: #1C3D5A;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 12px;
}

.logout-button:hover {
  color: #4A90E2;
}

.mobile-menu-button {
  display: none;
}

.social-icons {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-right: 1rem;
}

.social-link {
  color: #1C3D5A;
  font-size: 1.2rem;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
}

.social-link:hover {
  color: #4A90E2;
}

.quick-add {
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(26, 109, 255, 0.1);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
  color: #1a6dff;
  transition: all 0.2s ease;
  margin: 0.25rem 0;
}

.quick-add:hover {
  background: rgba(26, 109, 255, 0.2);
  transform: translateY(-1px);
}

.quick-add .nav-icon {
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .header-content {
    padding: 0.8rem 1rem;
    position: relative;
  }

  .logo {
    font-size: 1.1rem;
  }

  .nav {
    display: none;
  }

  .nav.active {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    max-height: 85vh;
    background: rgb(255, 255, 255);
    padding: 0.75rem;
    padding-top: calc(60px + 0.5rem);
    z-index: 1001;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    overflow-y: auto;
    animation: menuSlideDown 0.3s ease-out;
  }

  @keyframes menuSlideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .nav.active .nav-link {
    opacity: 0;
    animation: menuItemSlide 0.3s ease-out forwards;
  }

  .nav.active .nav-link:nth-child(1) { animation-delay: 0.1s; }
  .nav.active .nav-link:nth-child(2) { animation-delay: 0.15s; }
  .nav.active .nav-link:nth-child(3) { animation-delay: 0.2s; }
  .nav.active .nav-link:nth-child(4) { animation-delay: 0.25s; }
  .nav.active .nav-link:nth-child(5) { animation-delay: 0.3s; }

  @keyframes menuItemSlide {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .nav.active .quick-add {
    opacity: 0;
    animation: menuItemSlide 0.3s ease-out forwards;
    animation-delay: 0.35s;
  }

  .nav.active .logout-button {
    opacity: 0;
    animation: menuItemSlide 0.3s ease-out forwards;
    animation-delay: 0.4s;
  }

  .nav.active .social-icons {
    opacity: 0;
    animation: menuFadeIn 0.3s ease-out forwards;
    animation-delay: 0.45s;
  }

  @keyframes menuFadeIn {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .nav.active .nav-link {
    color: #1d1d1f;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    width: 100%;
    letter-spacing: -0.3px;
    padding: 6px 14px;
    border-radius: 10px;
    margin: 0;
    transition: all 0.2s ease;
  }

  .nav.active .nav-link:hover {
    background: rgba(74, 144, 226, 0.08);
    color: #4A90E2;
  }

  .nav.active .nav-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .nav.active .quick-add {
    margin: 0;
    padding: 6px 14px;
    border-radius: 10px;
    background: rgba(26, 109, 255, 0.08);
    font-size: 16px;
  }

  .nav.active .quick-add:hover {
    background: rgba(26, 109, 255, 0.12);
  }

  .nav.active .logout-button {
    color: #1d1d1f;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 14px;
    text-align: left;
    width: 100%;
    margin-top: 2px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    transition: all 0.2s ease;
  }

  .nav.active .logout-button:hover {
    background: rgba(74, 144, 226, 0.08);
    color: #4A90E2;
  }

  .nav.active .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    gap: 20px;
    width: 100%;
    padding: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .nav.active .social-link {
    color: #1d1d1f;
    font-size: 20px;
    padding: 8px;
    border-radius: 50%;
    transition: all 0.2s ease;
  }

  .nav.active .social-link:hover {
    background: rgba(74, 144, 226, 0.08);
    color: #4A90E2;
    transform: translateY(-1px);
  }

  .mobile-menu-button {
    display: flex;
    z-index: 1002;
    position: relative;
    padding: 0.6rem;
    background: transparent;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    margin-left: auto;
    cursor: pointer;
  }

  .nav.active ~ .mobile-menu-button {
    position: fixed;
    top: 12px;
    right: 16px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}

.result-section {
  margin-bottom: 2rem;
}

.result-section:last-child {
  margin-bottom: 0;
}

.result-section-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #666;
  margin: 0 0 1rem 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.result-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.85rem;
  color: #666;
}

.result-date {
  color: #666;
}

.result-location {
  color: #4A90E2;
}

@media (max-width: 768px) {
  .result-section-title {
    font-size: 0.85rem;
    margin-bottom: 0.75rem;
  }

  .result-meta {
    font-size: 0.8rem;
    gap: 0.75rem;
  }
} 