.tabButtons {
  display: flex;
  gap: 10px;
  background: #f5f5f5;
  padding: 8px;
  border-radius: 8px;
}

.tabBtn {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tabBtn:hover {
  background: rgba(74, 144, 226, 0.1);
  color: #4a90e2;
}

.tabBtn.active {
  background: #4a90e2;
  color: white;
  box-shadow: 0 2px 4px rgba(74, 144, 226, 0.2);
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .tabButtons {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .tabBtn {
    flex: 1;
    min-width: 120px;
    text-align: center;
  }
}

.completedCard {
  opacity: 0.8;
  background-color: #f8f8f8;
}

.completedText {
  text-decoration: line-through;
  color: #888;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  cursor: pointer;
}

.goalTitle, .habitTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.goalTitle h3, .habitTitle h3 {
  margin: 0;
  transition: all 0.2s ease;
}

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #4a90e2;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.checkbox:checked {
  background-color: #4a90e2;
}

.checkbox:checked::after {
  content: '✓';
  position: absolute;
  color: white;
  font-size: 14px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.checkbox:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

.goalFooterLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}

.goalFooterRight {
  display: flex;
  align-items: center;
  gap: 10px;
}

.postponeButton {
  padding: 4px 8px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.postponeButton:hover {
  background-color: #e0e0e0;
  color: #4a90e2;
}

.postponeCount {
  font-size: 12px;
  color: #888;
  background-color: #f0f0f0;
  padding: 2px 6px;
  border-radius: 4px;
}

/* 연기된 목표에 대한 시각적 표시 */
.postponedGoal {
  border-style: dashed;
}

.monthSelector {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  padding: 15px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.monthInput {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background-color: white;
}

.monthStats {
  font-size: 14px;
  color: #666;
  background-color: #f5f5f5;
  padding: 8px 12px;
  border-radius: 4px;
}

/* 월별 구분선 */
.monthDivider {
  margin: 20px 0;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .monthSelector {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .monthStats {
    text-align: center;
  }
}

.monthlyStatsContainer {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statsSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.statsSection h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.categoryStats {
  padding: 15px;
  background: #f8f8f8;
  border-radius: 8px;
}

.categoryHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.categoryDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.categoryName {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.statsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.statBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.statLabel {
  font-size: 12px;
  color: #666;
}

.progressBar {
  height: 6px;
  background: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  transition: width 0.3s ease;
}

.statText {
  font-size: 12px;
  color: #666;
  text-align: right;
}

@media (max-width: 768px) {
  .statsGrid {
    grid-template-columns: 1fr;
  }
}

.habitFormGrid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 20px;
  align-items: start;
}

.habitFormLeft {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.habitFormRight {
  background: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
}

.habitFormControls {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.selectWrapper, .targetWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.selectWrapper label, .targetWrapper label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.habitGoalSelect {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  color: #333;
}

.habitGoalSelect option {
  padding: 8px;
}

.habitGoalSelect:empty {
  border-color: #ff6b6b;
}

.targetInputGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.habitTargetInput {
  width: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.targetUnit {
  color: #666;
  font-size: 14px;
}

.targetHint {
  font-size: 12px;
  color: #888;
  margin-top: 4px;
}

.addHabitBtn {
  padding: 12px;
  background-color: #4ECDC4;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
  margin-top: 10px;
}

.addHabitBtn:hover {
  background-color: #45b7aa;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .habitFormGrid {
    grid-template-columns: 1fr;
  }

  .habitFormRight {
    padding: 15px;
  }

  .targetInputGroup {
    flex-wrap: wrap;
  }

  .habitTargetInput {
    width: 100%;
  }
}

.noGoalsHint {
  font-size: 12px;
  color: #ff6b6b;
  margin-top: 4px;
} 