@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.css");

.memo-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.memo-header {
  margin-bottom: 10px;
  padding: 1.75rem;
  background: #ffffff;
  border-radius: 16px;
  border: none;
  box-shadow: none;
}

.memo-header h1 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1d1d1f;
  margin-bottom: 1.5rem;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  letter-spacing: -0.022em;
}

.memo-commands {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  align-items: center;
}

.memo-commands p:first-child {
  color: #86868b;
  font-size: 0.9375rem;
  margin-right: 0.75rem;
  font-weight: 500;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

.command-btn {
  margin: 0;
  padding: 0.5rem 1rem;
  background: #ffffff;
  border-radius: 8px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  font-size: 0.9375rem;
  color: #0066cc;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;
  border: none;
  font-weight: 500;
}

.command-btn:hover {
  background: #f5f5f7;
  border-color: rgba(0, 0, 0, 0.15);
}

.command-btn:active {
  transform: scale(0.98);
  background: #e8e8ed;
}

.memo-command-examples {
  padding: 1.25rem;
  margin-top: 1rem;
  background: #ffffff;
  border-radius: 12px;
  border: none;
}

.examples-toggle {
  margin: 0;
  font-size: 0.9375rem;
  color: #1d1d1f;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  user-select: none;
}

.examples-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
  opacity: 0;
}

.examples-content.visible {
  max-height: 200px;
  margin-top: 1rem;
  opacity: 1;
}

.examples-content p {
  margin: 0;
  padding: 0.375rem 0;
  font-size: 0.875rem;
  color: #86868b;
  line-height: 1.6;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

.memo-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  padding: 0 1rem;
}

.memo-textarea {
  min-height: 140px;
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  color: #1d1d1f;
  font-size: 16px;
  line-height: 1.6;
  resize: none;
  outline: none;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  transition: border-color 0.2s ease;
  margin-bottom: 10px;
}

.memo-textarea:focus {
  border-color: #0066cc;
}

.memo-textarea::placeholder {
  color: #86868b;
  opacity: 0.8;
}

.added-todos {
  overflow-y: auto;
  max-height: 400px;
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 12px;
}

.todo-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  color: #000000 !important;
  transition: all 0.2s ease;
  border-radius: 12px;
  margin-bottom: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #ffffff;
  cursor: pointer;
  position: relative;
}

.todo-main-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  min-width: 0;
}

.todo-actions {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-left: 8px;
}

.action-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s ease;
  opacity: 0.6;
}

.action-btn:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.05);
}

.delete-btn {
  font-size: 20px;
  font-weight: bold;
  color: #ff4444;
}

.delete-btn:hover {
  background-color: rgba(255, 68, 68, 0.1);
}

.notification-btn {
  display: none;
}

.postpone-btn {
  width: 28px;
  height: 28px;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1em;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  opacity: 0.7;
}

.postpone-btn:hover {
  background-color: #f0f0f0;
  color: #333;
  opacity: 1;
}

.postpone-btn:active {
  transform: scale(0.95);
}

.todo-item:hover {
  border-color: rgba(0, 0, 0, 0.12);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.todo-checkbox {
  width: 22px;
  height: 22px;
  border-radius: 6px;
  appearance: none;
  background: #ffffff;
  border: 1.5px solid #d2d2d7;
  position: relative;
  flex-shrink: 0;
  transition: all 0.2s ease;
  pointer-events: none;
}

.todo-checkbox:checked {
  background: #0066cc;
  border-color: #0066cc;
}

.todo-checkbox:checked::after {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
}

.todo-content {
  flex: 1;
  min-width: 0;
  padding-right: 1rem;
}

.todo-task {
  font-size: 1rem;
  line-height: 1.5;
  color: #000000 !important;
  margin-bottom: 0.375rem;
  word-break: break-word;
  font-weight: 500;
  letter-spacing: -0.011em;
}

.todo-details {
  font-size: 0.875rem;
  color: #4a4a4a !important;
  line-height: 1.4;
  letter-spacing: -0.006em;
}

.notification-btn::before {
  content: '🔔';
  font-size: 0.875rem;
}

.notification-btn:disabled {
  opacity: 0.7;
}

.todo-item.completed {
  background: #f5f5f7;
  opacity: 0.8;
}

.todo-item.completed .todo-task {
  text-decoration: line-through;
  color: #4a4a4a !important;
}

.todo-item.completed .todo-details {
  color: #666666 !important;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .memo-container {
    padding: 1rem;
    min-height: calc(100vh - env(safe-area-inset-bottom, 0px));
  }

  .memo-header {
    padding: 1.25rem;
    border-radius: 12px;
  }

  .memo-header h1 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
  }

  .memo-commands {
    gap: 0.5rem;
  }

  .command-btn {
    padding: 0.4375rem 0.875rem;
    font-size: 0.875rem;
  }

  .memo-content {
    padding: 0;
  }

  .memo-textarea {
    padding: 1rem;
    font-size: 16px;
    border-radius: 10px;
  }

  .added-todos {
    padding: 0.25rem;
  }

  .todo-item {
    padding: 12px;
  }

  .todo-main-content {
    padding-right: 8px;
  }

  .todo-task {
    font-size: 0.9375rem;
  }

  .todo-details {
    font-size: 0.8125rem;
  }

  .todo-actions {
    gap: 2px;
  }

  .action-btn {
    width: 32px;
    height: 32px;
    padding: 6px;
  }

  .delete-btn {
    font-size: 24px;
  }

  .postpone-form {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    flex-direction: column;
    gap: 0.5rem;
  }

  .postpone-reason-input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
  }

  .postpone-form button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
  }

  /* Improve touch targets */
  .todo-checkbox {
    min-height: 20px;
    min-width: 20px;
  }

  .postpone-btn {
    min-height: 28px;
    min-width: 28px;
  }

  /* 모바일에서 버튼 터치 시 시각적 피드백 */
  .action-btn:active {
    transform: scale(0.9);
    background-color: rgba(0, 0, 0, 0.1);
  }

  .delete-btn:active {
    background-color: rgba(255, 68, 68, 0.2);
  }
}

.memo-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.memo-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.memo-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.memo-header-top h1 {
  margin: 0;
}

.manage-todos-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  color: #86868b;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.125rem;
  transition: all 0.2s ease;
}

.manage-todos-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #1d1d1f;
}

.manage-todos-btn:active {
  transform: scale(0.95);
  background-color: rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .manage-todos-btn {
    width: 28px;
    height: 28px;
    font-size: 1rem;
  }
}

.tech-interview-btn,
.interview-practice-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tech-interview-btn:hover,
.interview-practice-btn:hover {
  background-color: #f0f0f0;
} 

.todos-section {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-top: 0;
}

.todos-calendar-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  margin-top:20px;
}

@media (min-width: 1024px) {
  .todos-calendar-layout {
    flex-direction: row;
    gap: 2rem;
  }

  .todos-list-section {
    flex: 1;
    min-width: 0;
    overflow: hidden;
  }

  .todos-list-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    overflow-y: auto;
  }

  .calendar-section {
    width: 360px;
    flex-shrink: 0;
    position: sticky;
    top: 2rem;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
  }
}

@media (max-width: 1023px) {
  .memo-container {
    padding: 0.75rem;
  }

  .todos-list-section {
    margin-bottom: 1.5rem;
    order: -1;
  }

  .calendar-section {
    padding: 1rem;
    background: transparent;
    order: 1;
    margin-top: 2rem;
  }

  .todos-calendar-layout {
    display: flex;
    flex-direction: column;
  }

  .todos-list-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.today-todos {
  background: transparent;
  padding: 1.5rem 0;
  border-radius: 0;
  box-shadow: none;
}

.today-todos h2 {
  margin: 0 0 1rem 0;
  color: #000000 !important;
  font-size: 1.2rem;
}

.calendar-section {
  background: #ffffff;
  border-radius: 16px;
}

.calendar-section h2 {
  margin: 0 0 1.25rem 0;
  color: #000000 !important;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.022em;
}

.calendar-section .react-calendar {
  width: 100%;
  border: none;
  background: transparent;
  font-family: -apple-system, BlinkMacSystemFont, system-ui, Pretendard, sans-serif;
  line-height: 1.25;
  user-select: none;
}

/* Calendar navigation */
.react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
}

.react-calendar__navigation button {
  min-width: 32px;
  height: 32px;
  background: none;
  font-size: 0.9375rem;
  padding: 0;
  color: #000000 !important;
  border: none;
  margin: 0;
  font-weight: 500;
}

.react-calendar__navigation button:disabled {
  color: #86868b !important;
}

/* Month view */
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: none;
  font-weight: 500;
  font-size: 0.8125rem;
  color: #000000 !important;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
  cursor: default;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  gap: 0;
  background: transparent;
  padding: 0;
}

.react-calendar__month-view__days__day {
  aspect-ratio: 1;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  font-size: 0.875rem;
  color: #000000 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
  position: relative;
  min-height: 80px;
}

.react-calendar__month-view__days__day--weekend {
  color: #ff3b30 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #86868b !important;
}

.react-calendar__tile {
  position: relative;
  border: none;
  background: transparent !important;
  padding: 0;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-calendar__tile > abbr {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  transition: background-color 0.2s ease;
  color: #000000 !important;
}

.react-calendar__tile--now > abbr {
  color: #007aff !important;
  font-weight: 600;
}

.react-calendar__tile--active > abbr {
  background: #007aff !important;
  color: #ffffff !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #ff3b30 !important;
}

.react-calendar__month-view__days__day--neighboringMonth > abbr {
  color: #86868b !important;
}

/* Todo count badge */
.calendar-todos {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 4px;
}

.todo-count {
  background: #007aff !important;
  color: white !important;
  border-radius: 12px;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0 auto;
}

/* Mobile adjustments */
@media (max-width: 1023px) {
  .memo-container {
    padding: 0.75rem;
  }

  .todos-list-section {
    margin-bottom: 1.5rem;
  }

  .calendar-section {
    padding: 1rem;
    background: transparent;
  }

  .react-calendar__tile {
    height: 60px;
  }

  .react-calendar__tile > abbr {
    width: 28px;
    height: 28px;
    font-size: 0.8125rem;
    margin-top: 2px;
  }

  .calendar-todos {
    top: 32px;
  }

  .todo-count {
    min-width: 18px;
    height: 18px;
    font-size: 0.6875rem;
    padding: 0 4px;
  }

  .today-todos, .selected-date-todos {
    background: transparent;
    padding: 1rem 0;
    box-shadow: none;
  }

  .todo-item {
    background: #ffffff;
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 0.75rem;
  }

  .todo-item:hover {
    transform: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
}

/* Remove duplicate styles */
.react-calendar {
  border-radius: 0;
}

.selected-date-todos {
  background: transparent;
  padding: 1.5rem 0;
  border-radius: 0;
  box-shadow: none;
}

.selected-date-todos h3 {
  margin: 0 0 1rem 0;
  color: #000000 !important;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.022em;
}

.monthly-achievements {
  margin-bottom: 2rem;
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.monthly-achievements h2 {
  margin-bottom: 1rem;
  color: #333;
}

.achievements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.achievement-card {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 1rem;
  transition: transform 0.2s;
}

.achievement-card:hover {
  transform: translateY(-2px);
}

.achievement-content h4 {
  margin: 0 0 0.5rem 0;
  color: #333;
  font-size: 1rem;
}

.achievement-description {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.achievement-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.achievement-category {
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  color: white;
  font-size: 0.75rem;
  text-transform: capitalize;
}

.achievement-date {
  color: #666;
  font-size: 0.75rem;
}

.header-buttons {
  display: flex;
  gap: 8px;
}

.header-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.refresh-btn {
  opacity: 0.7;
}

.refresh-btn:hover {
  opacity: 1;
  transform: rotate(180deg);
}

.add-goal-small-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  color: #86868b;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.125rem;
  transition: all 0.2s ease;
}

.add-goal-small-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #1d1d1f;
}

.add-goal-small-btn:active {
  transform: scale(0.95);
  background-color: rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .add-goal-small-btn {
    width: 28px;
    height: 28px;
    font-size: 1rem;
  }
}

.monthly-achievements-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.monthly-achievements-header h2 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0;
  font-size: 1.25rem;
  color: #1d1d1f;
}

.month-nav-btn {
  background: none;
  border: none;
  color: #007aff;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.month-nav-btn:hover {
  background-color: rgba(0, 122, 255, 0.1);
}

.month-nav-btn:active {
  transform: scale(0.95);
}

.no-goals-message {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  color: #86868b;
  background: #f5f5f7;
  border-radius: 8px;
  font-size: 0.9375rem;
}

.achievement-date {
  color: #007aff;
  font-weight: 500;
  font-size: 0.8125rem;
}

@media (max-width: 768px) {
  .monthly-achievements-header {
    flex-direction: row;
    justify-content: center;
  }

  .monthly-achievements-header h2 {
    font-size: 1.125rem;
  }
}

.calendar-todos.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 0.8rem;
}

.calendar-loading {
  text-align: center;
  padding: 10px;
  color: #666;
  font-size: 0.9rem;
  background-color: #f5f5f7;
  border-radius: 8px;
  margin-top: 10px;
}

.today-habits-section {
  padding: 0;
  margin: 0;
}

.today-habits-section h2 {
  margin: 0 0 15px 0;
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: -0.022em;
}

.habits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.habit-check-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e9ecef;
}

.habit-check-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.habit-check-card.completed {
  background: #e8f5e9;
  border-color: #a5d6a7;
}

.habit-check-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.habit-check-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.habit-check-header h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.habit-check-box {
  width: 24px;
  height: 24px;
  border: 2px solid var(--apple-accent);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--apple-accent);
  font-size: 16px;
  font-weight: bold;
  transition: all 0.2s ease;
}

.completed .habit-check-box {
  background-color: var(--apple-accent);
  color: white;
}

.habit-goal-title {
  font-size: 12px;
  color: #666;
  margin: 0;
}

.habit-mini-stats {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  padding-top: 8px;
  border-top: 1px solid #e9ecef;
}

.content-layout {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.main-section {
  flex: 1;
}

@media (max-width: 768px) {
  .today-habits-section {
    padding: 0;
    margin: 0;
  }

  .today-habits-section h2 {
    display: none;
  }

  .habits-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0;
  }

  .habit-check-card {
    flex: 0 1 auto;
    padding: 8px 16px;
    background: #e8e8e8;
    border: none;
    border-radius: 6px;
  }

  .habit-check-content {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .habit-check-header {
    margin: 0;
  }

  .habit-check-header h3 {
    font-size: 0.9rem;
    color: #333;
    font-weight: 500;
  }

  .habit-check-box,
  .habit-goal-title,
  .habit-mini-stats {
    display: none;
  }

  .habit-check-card.completed {
    background: var(--apple-accent);
  }

  .habit-check-card.completed .habit-check-header h3 {
    color: white;
  }

  .todos-list-content {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

.postpone-form {
  display: flex;
  gap: 4px;
  align-items: center;
}

.postpone-reason-input {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
  width: 200px;
}

.postpone-submit-btn,
.postpone-cancel-btn {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}

.postpone-submit-btn {
  background-color: #4CAF50;
  color: white;
  border-color: #45a049;
}

.postpone-cancel-btn {
  background-color: #f44336;
  color: white;
  border-color: #da190b;
}

.postpone-submit-btn:hover {
  background-color: #45a049;
}

.postpone-cancel-btn:hover {
  background-color: #da190b;
}

.postponed-indicator {
  color: #ff6b6b;
  margin-right: 8px;
  font-size: 1.2em;
  letter-spacing: -2px;
  white-space: nowrap;
}

.todo-item.postponed {
  border-left: 3px solid #ff6b6b;
}

.todo-item.notification-scheduled {
  border-left: 3px solid #FFD700;
}

.todo-item.postponed.notification-scheduled {
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #ff6b6b 50%, #FFD700 50%) 1;
}

.todo-item.can-schedule {
  border-left: 3px solid #FFD700;
}

.todo-item.can-schedule:hover {
  border-left-color: #666;
}
.todo-item.postponed:hover {
  border-left-color: #666;
}
.postpone-reason {
  font-size: 0.9em;
  color: #666;
  margin-top: 4px;
  font-style: italic;
}

.delete-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.delete-btn:hover {
  opacity: 1;
}

.delete-confirm {
  display: flex;
  gap: 8px;
  align-items: center;
}

.delete-confirm span {
  font-size: 14px;
  color: #ff4444;
}

.delete-confirm-btn,
.delete-cancel-btn {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.delete-confirm-btn {
  background-color: #ff4444;
  color: white;
}

.delete-cancel-btn {
  background-color: #e0e0e0;
}

.delete-confirm-btn:hover {
  background-color: #ff0000;
}

.delete-cancel-btn:hover {
  background-color: #cccccc;
} 