.project-card {
  background: white;
  border: 1px solid #E5E9F0;
  border-radius: 0;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-card:hover {
  border-color: #A1C4FD;
}

.project-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 0;
  display: block;
}

.project-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.75rem;
}

.project-title {
  margin: 0;
  color: #1C3D5A;
  font-size: 0.95rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.project-description {
  color: #666;
  margin: 0;
  font-size: 0.8125rem;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex: 1;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.35rem;
  margin: 0;
}

.tag {
  padding: 0.15rem 0.5rem;
  border-radius: 2px;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.2;
}

.tag.role {
  background: #E3F2FD;
  color: #1976D2;
}

.tag.skill {
  background: #F5F7FA;
  color: #1C3D5A;
}

.project-links {
  display: flex;
  gap: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid #E5E9F0;
  margin: 0;
}

.project-link {
  color: #4A90E2;
  text-decoration: none;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.project-link:hover {
  color: #1C3D5A;
} 