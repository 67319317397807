.summary-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #111111;
  background-color: #ffffff;
}

.summary-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  padding: 1rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eaecef;
}

.summary-header h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #000000;
  letter-spacing: -0.022em;
  line-height: 1.1;
  margin: 0;
}

.search-container {
  margin: 1rem 0 2rem;
}

.search-input {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: #f9fafb;
}

.search-input:focus {
  outline: none;
  border-color: #0071e3;
  box-shadow: 0 0 0 4px rgba(0, 113, 227, 0.1);
  background-color: #ffffff;
}

.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 2rem;
}

.project-item {
  border: 1px solid #eaecef;
  border-radius: 12px;
  background: #ffffff;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
}

.project-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.project-info {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-header {
  margin-bottom: 1.5rem;
}

.project-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #000000;
  letter-spacing: -0.021em;
  margin: 0 0 0.75rem;
}

.project-description {
  color: #374151;
  line-height: 1.6;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.summaries-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  flex-grow: 1;
}

.summary-section {
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #eaecef;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.summary-header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: #ffffff;
  border-bottom: 1px solid #eaecef;
  border-radius: 8px 8px 0 0;
}

.summary-title {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.019em;
  margin: 0;
}

.copy-button {
  background: transparent;
  border: 1px solid #d1d5db;
  color: #374151;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.copy-button:hover {
  background: #f3f4f6;
  border-color: #9ca3af;
}

.copy-button:active {
  transform: translateY(1px);
}

.summary-content {
  white-space: pre-line;
  color: #374151;
  line-height: 1.6;
  font-size: 0.95rem;
  padding: 1rem;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 400px;
}

.summary-content ul {
  margin: 0;
  padding-left: 1.5rem;
}

.summary-content li {
  margin-bottom: 0.5rem;
  position: relative;
}

.summary-content li::before {
  content: '•';
  position: absolute;
  left: -1rem;
  color: #0071e3;
}

.button-group {
  padding: 1rem;
  display: flex;
  gap: 0.75rem;
  border-top: 1px solid #eaecef;
}

.button {
  flex: 1;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #0071e3;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background: #0077ed;
}

.button:active {
  transform: translateY(1px);
}

.button[disabled] {
  background: #d1d5db;
  cursor: not-allowed;
}

/* Tags */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

.tag {
  background: #f3f4f6;
  color: #374151;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.85rem;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .project-list {
    grid-template-columns: 1fr;
  }

  .summaries-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .summary-container {
    padding: 1rem;
  }

  .project-info {
    padding: 1rem;
  }

  .button-group {
    flex-direction: column;
  }

  .button {
    width: 100%;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .summary-container {
    background-color: #1a1a1a;
    color: #ffffff;
  }

  .summary-header {
    background: rgba(26, 26, 26, 0.95);
  }

  .summary-header h1 {
    color: #ffffff;
  }

  .search-input {
    background-color: #2c2c2c;
    border-color: #3a3a3a;
    color: #ffffff;
  }

  .search-input:focus {
    background-color: #333333;
  }

  .project-item {
    background: #2c2c2c;
    border-color: #3a3a3a;
  }

  .project-title {
    color: #ffffff;
  }

  .project-description {
    color: #e5e5e5;
  }

  .summary-section {
    background: #262626;
    border-color: #3a3a3a;
  }

  .summary-header-section {
    background: #2c2c2c;
    border-color: #3a3a3a;
  }

  .summary-title {
    color: #ffffff;
  }

  .copy-button {
    background: transparent;
    border-color: #4a4a4a;
    color: #e5e5e5;
  }

  .copy-button:hover {
    background: #3a3a3a;
    border-color: #6b7280;
  }

  .summary-content {
    color: #e5e5e5;
  }

  .button[disabled] {
    background: #4a4a4a;
    color: #8e8e8e;
  }

  .tag {
    background: #333333;
    color: #e5e5e5;
  }
} 