/* 기본 레이아웃 */
.add-blog {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

.add-blog h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #333;
}

/* 포스트 타입 선택 */
.post-type-selector {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.type-button {
  background: transparent;
  border: none;
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  transition: color 0.2s ease;
}

.type-button.active {
  color: #007bff;
  font-weight: 500;
}

/* Submit 버튼 */
.blog-publish-button {
  float: right;
  padding: 0.4rem 1rem;
  font-size: 0.8rem;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: calc(100% / 12);
  min-width: 80px;
  text-align: center;
}

.blog-publish-button:hover {
  background: #0056b3;
}

.blog-publish-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* 코딩 테스트 레이아웃 */
.coding-test-layout {
  clear: both;
  display: grid;
  grid-template-columns: 40% 1fr;
  gap: 2rem;
  margin-top: 1.5rem;
  position: relative;
  width: 100%;
}

/* 왼쪽 패널 */
.left-panel {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 2.5rem;
  border: 1px solid #eee;
  border-radius: 12px;
}

/* 제목과 내용 입력 영역 통합 */
.title-textarea,
.problem-description,
.file-name-input,
.textarea-wrapper textarea {
  width: 100%;
  border: none;
  font-family: inherit;
  color: #333;
  padding: 0;
  line-height: 1.6;
  resize: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-width: 0 !important;
  border-color: transparent !important;
}

/* 제목 입력 영역 특별 스타일 */
.form-group .title-textarea {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
  background: none !important;
  box-sizing: border-box !important;
}

/* 모든 입력 필드의 포커스 상태 제어 */
.title-textarea:focus,
.problem-description:focus,
.file-name-input:focus,
.textarea-wrapper textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  border-width: 0 !important;
  border-color: transparent !important;
}

.problem-description {
  font-size: 1rem;
  flex: 1;
  min-height: 500px;
}

.file-name-input {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  font-size: 0.9rem;
  color: #666;
  border-top: 1px solid #eee;
}

/* 오른쪽 패널 - 코드 에디터 */
.right-panel {
  position: relative;
  border: 1px solid #eee;
  border-radius: 12px;
  height: 100%;
}

.code-editor {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.code-actions {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #ffffff;
  border-bottom: 1px solid #eee;
}

.input-toggle-btn {
  background: transparent;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 0.4rem 1rem;
  border-radius: 3px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.input-toggle-btn:hover {
  background: #f0f7ff;
}

.run-code-btn {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 0.4rem 1.2rem;
  border-radius: 3px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.run-code-btn:hover {
  background: #0056b3;
}

/* 출력 영역 헤더 */
.output-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  background: #ffffff;
  border-bottom: 1px solid #eee;
}

.output-header span {
  color: #007bff;
  font-size: 0.9rem;
  font-weight: 500;
}

.clear-output-btn {
  background: transparent;
  color: #007bff;
  border: none;
  padding: 0.3rem 0.8rem;
  border-radius: 3px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clear-output-btn:hover {
  background: #f0f7ff;
}

/* 출력 영역 내용 */
.code-output {
  background: #fff;
  padding: 1rem;
  font-family: 'Fira Code', monospace;
  font-size: 0.9rem;
  color: #333;
  line-height: 1.6;
}

.code-output pre {
  margin: 0;
  white-space: pre-wrap;
}

/* 플레이스홀더 스타일 */
::placeholder {
  color: #aaa;
  opacity: 1;
}

/* CodeMirror 커스텀 스타일 */
.cm-editor {
  height: 100% !important;
  flex: 1;
  border: none !important;
  font-family: 'Fira Code', monospace !important;
}

.cm-editor .cm-scroller {
  height: 100% !important;
  line-height: 1.6 !important;
}

.cm-editor .cm-activeLine {
  background: #fff !important;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .coding-test-layout {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .submit-button {
    width: 100%;
  }
}

/* form 스타일 추가 */
.add-blog form {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* CodeMirror 에디터 컨테이너 수정 */
.python-compiler.form-group {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 일반 글 레이아웃 스타일 */
.form-group {
  margin-bottom: 2.5rem;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.8rem;
}

/* 일반 글 메인 컨텐츠 영역 */
.general-post-content {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 2.5rem;
  margin-bottom: 3rem;
}

/* 제목과 내용 입력 영역 통합 */
.form-group.title-group,
.form-group.content-group {
  margin: 0;
  border: none;
}

.form-group.title-group {
  margin-bottom: 2rem;
}

.form-group.title-group label,
.content-header label {
  display: none;  /* 레이블 숨김 */
}

.form-group .title-textarea {
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  border: none;
  padding: 0;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
  resize: none;
  outline: none;
  background: transparent;
}

.content-group {
  margin-top: 0;
}

.content-header {
  display: flex;
  justify-content: flex-end;  /* Preview 버튼을 오른쪽으로 */
  margin-bottom: 1rem;
}

.textarea-wrapper textarea {
  width: 100%;
  min-height: 400px;
  border: none;
  padding: 0;
  font-size: 1rem;
  line-height: 1.6;
  resize: none;
  outline: none;
  background: transparent;
  font-family: inherit;
}

/* 마크다운 프리뷰 */
.markdown-preview {
  padding: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

/* 프로젝트 선택 */
select {
  width: 100%;
  padding: 0.8rem;
  font-size: 0.9rem;
  border: 1px solid #eee;
  border-radius: 6px;
  background: #fff;
  color: #333;
  outline: none;
  cursor: pointer;
}

select:focus {
  border-color: #007bff;
}

/* 커버 이미지 입력 */
.cover-image-input {
  padding: 2rem;
  border: 1px dashed #eee;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cover-image-input:hover {
  border-color: #007bff;
  background: #f8f9fa;
}

.cover-image-input input[type="file"] {
  display: none;
}

.help-text {
  margin-top: 0.8rem;
  font-size: 0.8rem;
  color: #666;
}

/* 포스트 타입 입력 */
.type-input-container input {
  width: 100%;
  padding: 0.8rem;
  font-size: 0.9rem;
  border: 1px solid #eee;
  border-radius: 6px;
  outline: none;
}

.type-input-container input:focus {
  border-color: #007bff;
}

.type-suggestions {
  margin-top: 0.5rem;
}

.type-suggestion-item {
  padding: 0.5rem;
  cursor: pointer;
  color: #007bff;
  font-size: 0.9rem;
}

.type-suggestion-item:hover {
  background: #f8f9fa;
}

/* 드래그 오버레이 */
.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 123, 255, 0.05);
  border: 2px dashed #007bff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
  font-size: 1.1rem;
}
