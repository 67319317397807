.todo-management {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.todo-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.75rem;
  background: #f5f5f7;
  border-radius: 16px;
  border: none;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);
}

.todo-management-header h1 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 600;
  color: #1d1d1f;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  letter-spacing: -0.022em;
}

.back-button {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 102, 204, 0.1);
  color: #0066cc;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9375rem;
  font-weight: 500;
  transition: all 0.2s ease;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

.back-button:hover {
  background-color: rgba(0, 102, 204, 0.15);
}

.back-button:active {
  transform: scale(0.98);
  background-color: rgba(0, 102, 204, 0.2);
}

.date-group {
  margin-bottom: 2rem;
}

.date-header {
  font-size: 1.2rem;
  color: #1d1d1f;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  font-weight: 600;
}

.todo-item {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 0.75rem;
  padding: 1rem;
  transition: all 0.2s ease;
}

.todo-item:hover {
  border-color: rgba(0, 0, 0, 0.2);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.todo-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.todo-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
}

.todo-task {
  font-size: 1rem;
  color: #1d1d1f;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

.todo-task.completed {
  text-decoration: line-through;
  color: #86868b;
}

.todo-details {
  display: flex;
  gap: 1rem;
  color: #86868b;
  font-size: 0.9rem;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

.todo-actions {
  display: flex;
  gap: 0.5rem;
}

.edit-btn, .delete-btn {
  padding: 0.4rem 0.8rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  transition: all 0.2s ease;
}

.edit-btn {
  background-color: rgba(0, 102, 204, 0.1);
  color: #0066cc;
}

.delete-btn {
  background-color: rgba(255, 59, 48, 0.1);
  color: #ff3b30;
}

.edit-btn:hover {
  background-color: rgba(0, 102, 204, 0.15);
}

.delete-btn:hover {
  background-color: rgba(255, 59, 48, 0.15);
}

.todo-edit-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.todo-edit-form input {
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 0.9375rem;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  transition: border-color 0.2s ease;
}

.todo-edit-form input:focus {
  outline: none;
  border-color: #0066cc;
}

.todo-edit-form input[type="text"] {
  flex: 1;
  min-width: 200px;
}

.todo-edit-form input[type="time"] {
  width: 120px;
}

.edit-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
}

.save-btn, .cancel-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9375rem;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  transition: all 0.2s ease;
}

.save-btn {
  background-color: #34c759;
  color: white;
}

.cancel-btn {
  background-color: rgba(0, 0, 0, 0.1);
  color: #1d1d1f;
}

.save-btn:hover {
  background-color: #30b753;
}

.cancel-btn:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.todo-checkbox {
  width: 22px;
  height: 22px;
  border-radius: 6px;
  appearance: none;
  background: #ffffff;
  border: 1.5px solid #d2d2d7;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.todo-checkbox:checked {
  background: #0066cc;
  border-color: #0066cc;
}

.todo-checkbox:checked::after {
  content: '✓';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
}

.no-todos {
  text-align: center;
  padding: 3rem 1rem;
  color: #86868b;
  font-size: 1.1rem;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

.loading,
.loading-more {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 0.9rem;
}

.loading {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.loading-more {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .todo-management {
    padding: 1rem;
  }

  .todo-management-header {
    padding: 1.25rem;
    margin-bottom: 1.5rem;
  }

  .todo-management-header h1 {
    font-size: 1.5rem;
  }

  .todo-item {
    padding: 0.875rem;
  }

  .todo-actions {
    flex-direction: column;
  }

  .edit-btn, .delete-btn {
    padding: 0.375rem 0.75rem;
    font-size: 0.8125rem;
  }
} 