.blog {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, Roboto, sans-serif;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.blog h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.blog-posts-container {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.blog-post {
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  background: #fff;
  height: 300px;
  text-decoration: none;
  color: inherit;
  display: block;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);
}

.blog-post:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.blog-post-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-post:hover .cover-image {
  transform: scale(1.05);
}

.placeholder-image {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #f0f0f0, #e0e0e0);
}

.blog-post-content-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 32px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    transparent 100%
  );
  color: white;
}

.blog-post h2 {
  margin: 0 0 12px 0;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.003em;
  color: white;
}

.metadata {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  font-size: 14px;
  opacity: 0.9;
}

.post-type {
  background: rgba(255, 255, 255, 0.2);
  padding: 4px 12px;
  border-radius: 980px;
  font-size: 12px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.related-project {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.5rem;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.project-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.project-thumbnail {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  object-fit: cover;
}

.project-link:hover {
  opacity: 0.8;
}

.content-group {
  position: relative;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.preview-toggle {
  padding: 0.3rem 1rem;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.preview-toggle:hover {
  background-color: #e0e0e0;
}

.markdown-preview {
  min-height: 300px;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  line-height: 1.8;
  font-size: 1.1rem;
  color: #2c3e50;
}

.markdown-preview h1,
.markdown-preview h2,
.markdown-preview h3,
.markdown-preview h4,
.markdown-preview h5,
.markdown-preview h6 {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  color: #1a202c;
}

.markdown-preview h1 {
  font-size: 2.5rem;
  border-bottom: 2px solid #edf2f7;
  padding-bottom: 0.5rem;
}

.markdown-preview h2 {
  font-size: 2rem;
  border-bottom: 1px solid #edf2f7;
  padding-bottom: 0.5rem;
}

.markdown-preview h3 {
  font-size: 1.5rem;
}

.markdown-preview h4 {
  font-size: 1.25rem;
}

.markdown-preview p {
  margin-bottom: 1.5rem;
  line-height: 1.8;
}

.markdown-preview strong {
  font-weight: 600;
  color: #1a202c;
}

.markdown-preview em {
  font-style: italic;
}

.markdown-preview blockquote {
  margin: 2rem 0;
  padding: 1rem 1.5rem;
  border-left: 4px solid #4A90E2;
  background-color: #f8fafc;
  color: #4a5568;
  font-style: italic;
}

.markdown-preview blockquote p {
  margin: 0;
}

.markdown-preview code {
  background-color: #f7fafc;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  font-family: 'SF Mono', 'Consolas', 'Monaco', monospace;
  font-size: 0.9em;
  color: #e53e3e;
}

.markdown-preview pre {
  background-color: #2d3748;
  color: #e2e8f0;
  padding: 1.5rem;
  border-radius: 8px;
  overflow-x: auto;
  margin: 1.5rem 0;
}

.markdown-preview pre code {
  background-color: transparent;
  padding: 0;
  color: inherit;
  font-size: 0.95em;
  line-height: 1.6;
}

.markdown-preview ul,
.markdown-preview ol {
  margin: 1.5rem 0;
  padding-left: 2rem;
}

.markdown-preview li {
  margin-bottom: 0.5rem;
}

.markdown-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 2rem auto;
  display: block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.markdown-preview hr {
  margin: 3rem 0;
  border: 0;
  height: 1px;
  background-color: #edf2f7;
}

.markdown-preview table {
  width: 100%;
  margin: 2rem 0;
  border-collapse: collapse;
}

.markdown-preview th,
.markdown-preview td {
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
}

.markdown-preview th {
  background-color: #f7fafc;
  font-weight: 600;
  text-align: left;
}

.markdown-preview tr:nth-child(even) {
  background-color: #f9fafb;
}

.markdown-preview a {
  color: #4A90E2;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s;
}

.markdown-preview a:hover {
  border-bottom-color: #4A90E2;
}

/* 코드 블록 스크롤바 스타일링 */
.markdown-preview pre::-webkit-scrollbar {
  height: 8px;
}

.markdown-preview pre::-webkit-scrollbar-track {
  background: #374151;
  border-radius: 4px;
}

.markdown-preview pre::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 4px;
}

.markdown-preview pre::-webkit-scrollbar-thumb:hover {
  background: #606f7b;
}

@media (max-width: 768px) {
  .markdown-preview {
    padding: 1.5rem;
    font-size: 1rem;
  }

  .markdown-preview h1 {
    font-size: 2rem;
  }

  .markdown-preview h2 {
    font-size: 1.75rem;
  }

  .markdown-preview h3 {
    font-size: 1.4rem;
  }
}

.textarea-wrapper {
  position: relative;
  width: 100%;
}

.textarea-wrapper textarea {
  width: 100%;
  min-height: 300px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  resize: vertical;
}

.textarea-wrapper.dragging textarea {
  border-color: #4A90E2;
  background-color: rgba(74, 144, 226, 0.05);
}

.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #4A90E2;
  pointer-events: none;
  border: 2px dashed #4A90E2;
  border-radius: 4px;
}

.textarea-wrapper textarea:focus {
  outline: none;
  border-color: #4A90E2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

/* 이미지 업로드 진행 중일 때의 스타일 */
.textarea-wrapper.uploading textarea {
  opacity: 0.7;
  cursor: wait;
}

.metadata .date-time {
  color: #4A90E2;
  font-weight: 500;
}

.blog-details-meta .date-time {
  color: #4A90E2;
  font-weight: 500;
}

.title-textarea {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: inherit;
  resize: vertical;
  min-height: 60px;
  line-height: 1.5;
}

.title-textarea:focus {
  outline: none;
  border-color: #4A90E2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.related-project-link {
  text-decoration: none;
  color: inherit;
}

.blog-container {
  max-width: 980px;
  margin: 0 auto;
  padding: 40px 20px;
}

.type-cloud {
  background: #ffffff;
  border-radius: 18px;
  padding: 32px;
  margin-bottom: 40px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);
  transition: all 0.3s cubic-bezier(0.28, 0.11, 0.32, 1);
  overflow: hidden;
}

.type-cloud.visible {
  max-height: 1000px;
}

.type-cloud:not(.visible) {
  max-height: 80px;
  padding-bottom: 16px;
}

.type-cloud-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.type-cloud-header h3 {
  margin: 0;
  color: #1d1d1f;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.003em;
  line-height: 1.16667;
}

.toggle-cloud-btn {
  background: none;
  border: none;
  color: #06c;
  cursor: pointer;
  padding: 8px 18px;
  border-radius: 980px;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s cubic-bezier(0.28, 0.11, 0.32, 1);
}

.toggle-cloud-btn:hover {
  background-color: rgba(0, 102, 204, 0.1);
  color: #06c;
}

.type-cloud-content {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  transition: opacity 0.2s cubic-bezier(0.28, 0.11, 0.32, 1);
  opacity: 1;
  transform-origin: top;
}

.type-cloud:not(.visible) .type-cloud-content {
  opacity: 0;
  pointer-events: none;
}

.type-tag {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #f5f5f7;
  border-radius: 980px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.28, 0.11, 0.32, 1);
  user-select: none;
  color: #1d1d1f;
  font-size: 14px;
  font-weight: 400;
  border: none;
  opacity: 1;
}

.type-tag:hover {
  background-color: #e8e8ed;
  transform: translateY(-1px);
}

.type-tag.selected {
  background-color: #06c;
  color: white;
}

.type-tag:not(.selected):hover {
  opacity: 0.8;
}

.type-count {
  margin-left: 8px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.05);
  padding: 2px 8px;
  border-radius: 980px;
  font-weight: 400;
  min-width: 24px;
  text-align: center;
}

.type-tag.selected .type-count {
  background: rgba(255, 255, 255, 0.2);
}

.current-filter {
  display: inline-flex;
  align-items: center;
  background-color: #06c;
  color: white;
  padding: 8px 18px;
  border-radius: 980px;
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 400;
}

.clear-filter {
  background: none;
  border: none;
  color: white;
  margin-left: 12px;
  cursor: pointer;
  padding: 0 6px;
  font-size: 16px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.28, 0.11, 0.32, 1);
}

.clear-filter:hover {
  opacity: 0.8;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

@media (max-width: 768px) {
  .blog-container {
    padding: 0;
    margin: 0;
  }

  .type-cloud {
    position: sticky;
    top: 0;
    z-index: 100;
    border-radius: 0;
    margin: 0;
    padding: 16px 20px;
    background-color: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .type-cloud:not(.visible) {
    max-height: 60px;
    padding-bottom: 16px;
  }

  .type-cloud-header {
    margin-bottom: 16px;
  }

  .type-cloud-header h3 {
    font-size: 17px;
    font-weight: 600;
    color: #000000;
    letter-spacing: -0.022em;
  }

  .toggle-cloud-btn {
    font-size: 15px;
    font-weight: 500;
    color: #007AFF;
    padding: 8px 12px;
    border-radius: 8px;
    transition: background-color 0.2s;
  }

  .toggle-cloud-btn:active {
    background-color: rgba(0, 122, 255, 0.1);
  }

  .type-cloud-content {
    gap: 8px;
    padding: 4px 0;
  }

  .type-tag {
    padding: 6px 14px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 980px;
    background-color: rgba(142, 142, 147, 0.1);
    border: none;
    color: #000000;
    transition: all 0.2s;
  }

  .type-tag:active {
    background-color: rgba(142, 142, 147, 0.2);
    transform: scale(0.98);
  }

  .type-tag.selected {
    background-color: #007AFF;
    color: white;
  }

  .type-count {
    font-size: 13px;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.06);
    padding: 2px 8px;
    border-radius: 980px;
    min-width: 22px;
  }

  .type-tag.selected .type-count {
    background: rgba(255, 255, 255, 0.2);
  }

  .current-filter {
    margin: 16px 20px;
    background-color: #007AFF;
    font-size: 15px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 980px;
  }

  .clear-filter {
    font-size: 17px;
    opacity: 1;
  }

  .blog-posts {
    padding: 0 16px;
    gap: 16px;
  }

  .blog-post {
    height: 220px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  .blog-post-content-overlay {
    padding: 16px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.85) 0%,
      rgba(0, 0, 0, 0.4) 60%,
      transparent 100%
    );
  }

  .blog-post h2 {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.022em;
    margin-bottom: 8px;
  }

  .metadata {
    font-size: 13px;
    gap: 8px;
  }

  .post-type {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .no-posts {
    margin: 32px 16px;
    padding: 24px;
    font-size: 15px;
    color: #8E8E93;
    background-color: rgba(142, 142, 147, 0.1);
    border-radius: 12px;
    text-align: center;
  }

  .loading {
    padding: 32px 16px;
    font-size: 15px;
    color: #8E8E93;
  }

  .error-message {
    margin: 16px;
    padding: 16px;
    font-size: 15px;
    background-color: #FFE5E5;
    color: #FF3B30;
    border-radius: 12px;
  }
}

.loading {
  text-align: center;
  padding: 64px 20px;
  color: #86868b;
  font-size: 17px;
  font-weight: 400;
}

.error-message {
  text-align: center;
  padding: 24px;
  color: #e30000;
  background-color: rgba(227, 0, 0, 0.05);
  border-radius: 18px;
  margin: 32px auto;
  max-width: 600px;
  font-size: 17px;
}

.no-posts {
  text-align: center;
  padding: 48px 24px;
  color: #86868b;
  background-color: #f5f5f7;
  border-radius: 18px;
  grid-column: 1 / -1;
  font-size: 17px;
  font-weight: 400;
}

/* Notion 스타일 마크다운 */
.blog-details-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  color: #37352f;
  line-height: 1.8;
}

.blog-details-content .markdown-preview {
  border: none;
  padding: 0;
  font-size: 1rem;
}

.blog-details-content h1,
.blog-details-content h2,
.blog-details-content h3,
.blog-details-content h4,
.blog-details-content h5,
.blog-details-content h6 {
  font-weight: 600;
  line-height: 1.3;
  margin: 2em 0 1em;
  color: #37352f;
}

.blog-details-content h1 {
  font-size: 2.4em;
  margin-top: 0;
  border-bottom: none;
}

.blog-details-content h2 {
  font-size: 1.875em;
  border-bottom: none;
}

.blog-details-content h3 {
  font-size: 1.5em;
}

.blog-details-content p {
  margin: 1em 0;
  color: #37352f;
}

.blog-details-content ul,
.blog-details-content ol {
  margin: 1em 0;
  padding-left: 1.5em;
}

.blog-details-content li {
  margin: 0.5em 0;
  color: #37352f;
}

.blog-details-content blockquote {
  margin: 1.5em 0;
  padding: 0.8em 1em;
  border-left: 4px solid #e9ecef;
  background-color: #f8f9fa;
  border-radius: 4px;
  color: #495057;
  font-style: normal;
}

.blog-details-content code {
  background: rgba(135, 131, 120, 0.15);
  color: #eb5757;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.875em;
  font-family: 'SF Mono', SFMono-Regular, ui-monospace, Menlo, Consolas, monospace;
}

.blog-details-content pre {
  background-color: #f7f6f3;
  padding: 1.5em;
  border-radius: 4px;
  overflow-x: auto;
  margin: 1.5em 0;
}

.blog-details-content pre code {
  background: none;
  color: #37352f;
  padding: 0;
  font-size: 0.875em;
  line-height: 1.6;
}

.blog-details-content img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 2em auto;
  display: block;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 1px 2px;
}

.blog-details-content hr {
  border: none;
  height: 1px;
  background-color: #e9ecef;
  margin: 2em 0;
}

.blog-details-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 2em 0;
  font-size: 0.875em;
}

.blog-details-content th,
.blog-details-content td {
  padding: 0.75em;
  border: 1px solid #e9ecef;
}

.blog-details-content th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #37352f;
}

.blog-details-content a {
  color: #2b6cb0;
  text-decoration: underline;
  text-decoration-color: rgba(43, 108, 176, 0.3);
  transition: text-decoration-color 0.2s;
}

.blog-details-content a:hover {
  text-decoration-color: rgba(43, 108, 176, 0.8);
}

/* 체크리스트 스타일 */
.blog-details-content ul.contains-task-list {
  list-style: none;
  padding-left: 0;
}

.blog-details-content .task-list-item {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.blog-details-content .task-list-item input[type="checkbox"] {
  width: 1em;
  height: 1em;
  margin: 0;
}

/* 코드 블록 스크롤바 */
.blog-details-content pre::-webkit-scrollbar {
  height: 6px;
}

.blog-details-content pre::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.blog-details-content pre::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 3px;
}

.blog-details-content pre::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}

/* 모바일 반응형 */
@media (max-width: 768px) {
  .blog-details-content {
    padding: 1.5rem;
  }

  .blog-details-content h1 {
    font-size: 2em;
  }

  .blog-details-content h2 {
    font-size: 1.5em;
  }

  .blog-details-content h3 {
    font-size: 1.25em;
  }
}

.code-editor {
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.code-editor .cm-editor {
  font-family: 'Fira Code', 'Consolas', monospace;
  font-size: 14px;
}

.code-editor .cm-gutters {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.code-editor .cm-activeLineGutter {
  background-color: rgba(255, 255, 255, 0.05);
}

.code-editor .cm-content {
  padding: 10px 0;
}

.code-editor .cm-line {
  padding: 0 10px;
}

.content-actions {
  display: flex;
  gap: 1rem;
}

.code-editor-container {
  margin: 1rem 0;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.language-select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  font-size: 0.9rem;
}

.insert-code-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #4A90E2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.insert-code-btn:hover {
  background: #357ABD;
}

.preview-toggle {
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.preview-toggle:hover {
  background: #e0e0e0;
}

.blog-content {
  padding: 1rem;
  line-height: 1.6;
  color: #333;
}

.blog-code-block {
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-code-block pre {
  margin: 0;
  padding: 1rem;
  background: #282c34;
  color: #abb2bf;
  overflow-x: auto;
}

.blog-code-block code {
  font-family: 'Fira Code', 'Consolas', monospace;
  font-size: 14px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .blog-content {
    padding: 0.8rem;
    font-size: 0.95rem;
  }

  .blog-code-block {
    margin: 0.8rem 0;
    border-radius: 6px;
  }

  .blog-code-block pre {
    padding: 0.8rem;
    font-size: 13px;
  }
}

.code-actions {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  background: #282c34;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.run-code-btn {
  padding: 0.5rem 1rem;
  background: #98c379;
  color: #282c34;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s;
}

.run-code-btn:hover {
  background: #7cb058;
}

.run-code-btn:disabled {
  background: #6a6f7c;
  cursor: not-allowed;
}

.code-output {
  background: #21252b;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.output-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #2c313a;
  color: #abb2bf;
  font-size: 0.9rem;
}

.clear-output-btn {
  padding: 0.25rem 0.5rem;
  background: #4b5263;
  color: #abb2bf;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.2s;
}

.clear-output-btn:hover {
  background: #5c6370;
}

.code-output pre {
  margin: 0;
  padding: 1rem;
  color: #98c379;
  font-family: 'Fira Code', 'Consolas', monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  overflow-x: auto;
  white-space: pre-wrap;
}

.code-output pre.error {
  color: #e06c75;
}

@media (max-width: 768px) {
  .code-actions {
    padding: 0.4rem;
  }

  .run-code-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }

  .output-header {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }

  .code-output pre {
    padding: 0.8rem;
    font-size: 0.85rem;
  }
}

.code-input {
  background: #282c34;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1rem;
}

.code-input textarea {
  width: 100%;
  background: #21252b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: #abb2bf;
  font-family: 'Fira Code', 'Consolas', monospace;
  font-size: 0.9rem;
  padding: 0.5rem;
  resize: vertical;
}

.code-input textarea:focus {
  outline: none;
  border-color: #528bff;
}

.input-toggle-btn {
  padding: 0.5rem 1rem;
  background: #4b5263;
  color: #abb2bf;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
  margin-right: auto;
}

.input-toggle-btn:hover {
  background: #5c6370;
}

.file-name-input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.file-name-input:focus {
  outline: none;
  border-color: #4A90E2;
}

.file-name-input::placeholder {
  color: #999;
} 